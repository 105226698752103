import React, { Component, Fragment } from 'react';
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "./assets/css/global";
import Routes from './router';
import SwalTwo from "sweetalert2"
import { connect } from "react-redux";
import Session from "./store/actions/session.actions";
import moment from "moment";
import "moment/locale/pt-br";
import './assets/css/antd.css'
import './assets/css/helpers.css'
import Loading from './components/Loading';
moment.locale("pt-BR");

class App extends Component {
  constructor(props) {
    super()
    this.state = {
      loading: true,
      actions: {
        unpackToken: this.unpackToken
      }
    }
    this.unpackToken = this.unpackToken.bind(this)
  }

  unpackToken = async (data, firstInit) => {
    var dateInUse;
    if (!firstInit) {
      await localStorage.setItem("KAAZA", JSON.stringify(data))
      dateInUse = data
    } else {
      var date = await localStorage.getItem("KAAZA")
      dateInUse = JSON.parse(date)
      if (dateInUse) {
        let timeLeft = moment(dateInUse.expirateAt).diff(
          moment(new Date()),
          "minutes"
        )
        if (timeLeft < 0) {
          dateInUse = await localStorage.removeItem("KAAZA")
          this.setState(state => ({ loading: false }))
          return SwalTwo.fire({
            icon: "error",
            title: "Sessão expirada"
          })
        }
      }
    }
    if (dateInUse && dateInUse.token) {
      const base64Url = dateInUse.token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      let user = JSON.parse(window.atob(base64));
      await this.props.sessionLogin({ ...user, token: dateInUse.token });
    }

    this.setState(state => ({ loading: false }))

  }

  componentDidMount() {
    this.unpackToken(null, true)
  }

  render() {
    return (
      <Fragment>
        {this.state.loading
          ? <Loading />
          : <BrowserRouter>
            <GlobalStyle />
            <Routes appState={this.state} />
          </BrowserRouter>
        }
      </Fragment>
    );
  }
}


const mapStateToProps = ({ sessionReducers }) => ({
  session: sessionReducers
});

const mapDispatchToProps = dispatch => ({
  sessionLogin: user => dispatch(Session.Login(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
