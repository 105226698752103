import styled from 'styled-components';

export const Container = styled.div`
  .ant-select,
  .ant-select-lg,
  .ant-select-single,
  .ant-select-show-arrow {
    border: 1px solid #4e446f;
  }
  .ant-layout-sider {
    max-height: 100vh;
    overflow-y: auto;
  }
  .ant-layout-content {
    height: 100vh;
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-layout {
    background-color: #e8e8e8;
  }
`;
