import api from "./api"

const login = async (login, pass) => {
    return await api.post('/session/login', { login, pass })
}

const handleStorage = (action, value) => {
    Date.prototype.addHours = function (h) {
      this.setHours(this.getHours() + h);
      return this;
    };
  
    var storage;
    switch (action) {
      case "SET":
        localStorage.setItem(
          "JETO#USER",
          JSON.stringify({
            ...value,
            expires: new Date(),
            expires_end: new Date().addHours(3)
          })
        );
        storage = localStorage.getItem("JETO#USER");
        break;
  
      case "GET":
        storage = localStorage.getItem("JETO#USER");
        return JSON.parse(storage);
      case "REMOVE":
        localStorage.removeItem("JETO#USER");
        break;
  
      default:
        break;
    }
  };

export const session = { login, handleStorage }