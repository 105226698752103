import styled from "styled-components";

export const Container = styled.div`
  aside {
    background-color: #222222;
    min-height: 100vh;
    .logo-content {
        padding: 2rem 0;
      img {
        width: 70%;
        margin: 0 auto;
      }
    }
    .user-content {
      background-color: #333333;
      display: flex;
      align-items: center;
      flex-flow: column;
      padding: 20px 0;
      color: #FFF;
      line-height: 1.3;
      font-size: 13px;

      h5 {
        color: white;
        margin: 5px auto;
      }
    }
    .ant-menu-inline-collapsed {
      .ant-menu-item {
        .menu-icon {
          min-width: 40px;
          display: flex;
          justify-content: center;
          .icon {
              max-width: 20px;
          }
        }
        span {
          opacity: 0;
        }
      }
    }
    .ant-menu {
      overflow-y: auto;
      background-color: #222222;  
      .ant-menu-item {
        padding: 0px 20px !important;
        display: flex;
        align-items: center;
        margin: 0 !important;

        &.ant-menu-item-selected {
          background-color: var(--purple)
        }

          img {
            max-width: 30px;
            width: auto;
            height: 25px;
        }
        span {
          margin-left: 10px;
          font-size: 13px;
        }
      }
    }
  }
`;
