import axios from 'axios';

const api = axios.create({
  baseURL: 'https://kaaza-api-prod.creativecode.dev.br/',
  // baseURL: 'http://localhost:5000',
});

const datas = localStorage.getItem('KAAZA');

if (datas)
  api.defaults.headers.authorization = `BEARER ${JSON.parse(datas).token}`;

export default api;
