import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${props => props.minHeight ? props.minHeight : '100vh'};
  max-height: ${props => props.maxHeight ? props.maxHeight : '100vh'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background ? props.background : '#222 !important'};

  svg {
    fill:  ${props => props.background ? "#222 !important" : 'white'};
  }
`;
