import React, { Suspense, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import Sidenav from "../../components/Sidenav";
import { Container } from "./styles"
import "../../assets/css/antd.css"
import { Layout } from 'antd';
import { Switch, Route } from "react-router-dom";

const Home = React.lazy(() => import("../Home"));
const Apartamentos = React.lazy(() => import("../Apartamentos"));
const Lots = React.lazy(() => import("../Lots"));
const Usuarios = React.lazy(() => import("../Usuarios"));
const Empreendimentos = React.lazy(() => import("../Empreendimentos"));
const EmpreendimentoAreas = React.lazy(() => import("../EmpreendimentosAreas"));
const Empresas = React.lazy(() => import("../Empresas"));
const CreateEditApartment = React.lazy(() => import("../CreateEditApartment"));
const CreateMultipleApartment = React.lazy(() => import("../CreateMultipleApartment"));
const CreateEditDevelopment = React.lazy(() => import("../CreateEditDevelopment"));
const CreateEditUser = React.lazy(() => import("../CreateEditUser"));
const CreateEditCompany = React.lazy(() => import("../CreateEditCompany"));

const { Content } = Layout;

const Structure = (props) => {
    const [collapsed] = useState(false)

    return (
        <Container>
            <Layout>
                <Sidenav history={props.history} collapsed={false} />
                <Layout>
                    <Content>
                        <Suspense
                            fallback={
                                <Loading background="#f0f2f5" />
                            }
                        >
                            <Switch>
                                <Route
                                    path={`/dashboard`}
                                    exact
                                    render={(props) => (
                                        <Home config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/apartamentos`}
                                    exact
                                    render={(props) => (
                                        <Apartamentos config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/lotes`}
                                    exact
                                    render={(props) => (
                                        <Lots config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/apartamento`}
                                    exact
                                    render={(props) => (
                                        <CreateEditApartment config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/multiplos-apartamentos`}
                                    exact
                                    render={(props) => (
                                        <CreateMultipleApartment config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/empreendimentos`}
                                    exact
                                    render={(props) => (
                                        <Empreendimentos config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/empreendimento-areas/:id`}
                                    render={(props) => (
                                        <EmpreendimentoAreas config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/empreendimento`}
                                    exact
                                    render={(props) => (
                                        <CreateEditDevelopment config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/empresas`}
                                    exact
                                    render={(props) => (
                                        <Empresas config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/empresa`}
                                    exact
                                    render={(props) => (
                                        <CreateEditCompany config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/usuarios`}
                                    exact
                                    render={(props) => (
                                        <Usuarios config={props.config} {...props} />
                                    )}
                                />
                                <Route
                                    path={`/dashboard/usuario`}
                                    exact
                                    render={(props) => (
                                        <CreateEditUser config={props.config} {...props} />
                                    )}
                                />
                            </Switch>
                        </Suspense>
                    </Content>
                </Layout>
            </Layout>
        </Container>
    )
}

const mapStateToProps = ({ sessionReducers }) => ({
    session: sessionReducers
});

export default connect(mapStateToProps)(Structure);
