import React, { Component, Suspense } from "react"
import { Switch, Route, Router, BrowserRouter } from "react-router-dom"
import history from "./history";
import Loading from "../components/Loading"
import ScrollToTop from "./scrollToTop"
import Login from "../pages/Login"
import Structure from "../pages/Structure";



class Routes extends Component {
    render() {
        return (
            <Suspense fallback={<Loading />}>
                <Switch>
                    <BrowserRouter history={history}>
                        <ScrollToTop />
                        <Route
                            path="/"
                            exact
                            render={(props) => (
                                <Login config={this.props.appState} {...props} />
                            )}
                        />
                        <Route
                            path="/dashboard/:subpage?/:interpage?"
                            render={(props) => (
                                <Structure config={this.props.stateConfig} {...props} />
                            )}
                        />
                    </BrowserRouter>
                </Switch>
            </Suspense>
        )
    }
}

export default Routes