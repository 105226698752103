import React, { Component } from "react";

import { Layout, Menu } from "antd";
import { connect } from "react-redux";
import Home from "../../assets/images/painel-de-controle.svg";
import Usuario from "../../assets/images/avatar.svg";
import Apartament from "../../assets/images/apartment.svg";
import Empreendimento from "../../assets/images/empresa.svg";
import Buildings from "../../assets/images/buildings.svg";
import Lots from "../../assets/images/terreno.png";
import Sair from "../../assets/images/sair.svg";
import Logo from "../../assets/images/logo.png";
import { Container } from "./styles";
import Session from "../../store/actions/session.actions";

const { Sider } = Layout;

class Sidenav extends Component {
  state = {
    selectedPage: 0,
  };

  componentDidMount() {
    this.detectPage();
  }

  detectPage = () => {
    let currentKey = 0;
    let target = this.props.history.location.pathname.replace("/", "");
    switch (target) {
      case "dashboard":
        currentKey = 1;
        break;
      case "dashboard/apartamentos":
        currentKey = 2;
        break;
      case "dashboard/lotes":
        currentKey = 3;
        break;
      case "dashboard/empreendimentos":
        currentKey = 4;
        break;
      case "dashboard/empresas":
        currentKey = 5;
        break;
      case "dashboard/usuarios":
        currentKey = 6;
        break;
      default:
        break;
    }
    this.setState((state) => ({ selectedPage: currentKey }));
  };
  render() {
    const {
      currentRole,
      currentRoleDesc,
      currentCompanyDesc,
      currentName,
    } = this.props.session.user;
    return (
      <Container>
        <Sider
          trigger={null}
          collapsible
          width={250}
          collapsed={this.props.collapsed}
          breakpoint="sm"
          collapsedWidth={window.innerWidth > 800 ? "initial" : 0}
        >
          <div className="logo-content flex alignCenter justifyCenter">
            <img src={Logo} alt="Kaaza" />
          </div>
          <div className="user-content">
            <div>Bem vindo</div>
            <h5 className="semibold margin_05_top white-text">{currentName}</h5>
            <div>
              {currentRole === "ADMIN"
                ? currentRoleDesc
                : currentRoleDesc + " | " + currentCompanyDesc}
            </div>
          </div>
          <Menu
            selectedKeys={[this.state.selectedPage.toString()]}
            theme="dark"
            mode="inline"
            onClick={() => this.detectPage()}
          >
            {this.props.session.user.currentRole !== "SELLER" && (
              <Menu.Item
                key="1"
                onClick={(e) => this.props.history.push("/dashboard")}
                icon={<Icon image={Home} />}
              >
                Painel de controle
              </Menu.Item>
            )}
            <Menu.Item
              key="2"
              onClick={(e) =>
                this.props.history.push("/dashboard/apartamentos")
              }
              icon={<Icon image={Apartament} />}
            >
              Apartamentos
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={(e) => this.props.history.push("/dashboard/lotes")}
              icon={<Icon image={Lots} />}
            >
              Lotes
            </Menu.Item>
            {this.props.session.user.currentRole !== "SELLER" && (
              <Menu.Item
                onClick={(e) =>
                  this.props.history.push("/dashboard/empreendimentos")
                }
                key="4"
                icon={<Icon image={Buildings} />}
              >
                Empreendimentos
              </Menu.Item>
            )}
            {this.props.session.user.currentRole === "ADMIN" && (
              <Menu.Item
                key="5"
                onClick={(e) => this.props.history.push("/dashboard/empresas")}
                icon={<Icon image={Empreendimento} />}
              >
                Empresas
              </Menu.Item>
            )}
            {this.props.session.user.currentRole !== "SELLER" && (
              <Menu.Item
                key="6"
                onClick={(e) => this.props.history.push("/dashboard/usuarios")}
                icon={<Icon image={Usuario} />}
              >
                Usuários
              </Menu.Item>
            )}
            <Menu.Item
              key="7"
              onClick={async (e) => {
                this.props.history.push("/");
                await this.props.sessionLogout();
                localStorage.removeItem("KAAZA");
              }}
              icon={<Icon image={Sair} />}
            >
              Sair
            </Menu.Item>
          </Menu>
        </Sider>
      </Container>
    );
  }
}

const Icon = (props) => {
  return (
    <div className="menu-icon">
      <img src={props.image} alt="icon" className="icon" />
    </div>
  );
};

const mapStateToProps = ({ sessionReducers }) => ({
  session: sessionReducers,
});

const mapDispatchToProps = (dispatch) => ({
  sessionLogout: () => dispatch(Session.Logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
