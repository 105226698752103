import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--blueDark);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 200px;
  }

  .recover-password {
    text-align: center;

    button {
      font-size: 15px !important;
      font-weight: 500 !important;
      background: transparent !important;
      color: #333 !important;
      border: 0 !important;
      display: inline !important;
      width: auto !important;
      height: auto !important;
    }
  }

  .content {
    width: 40%;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    .content-inner {
      background-color: white;
      margin-top: 2rem;
      width: 100%;

      .input {
        display: grid;
        margin: 0 15px;
        grid-template-columns: 40px 1fr;
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          font-size: 14px;
          background-color: var(--blue);
          color: white;
        }
      }

      .header {
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        text-align: center;
      }

      input {
        border: 1px solid #ccc !important;
        border-radius: 0px !important;
        font-size: 14px;
        max-width: calc(100% - 10px) !important;
      }

      button {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background-color: var(--blue);
        border: 2px solid var(--blue);
        transition: 0.3s;
        color: white;
        font-weight: 500;
        cursor: pointer;

        &:disabled {
          background-color: #ccc !important;
          border: 2px solid #ccc !important;
        }

        &:hover {
          background-color: #2868c7;
        }
      }
    }
  }
`;
